import React from "react"
import { Link, graphql } from "gatsby"
import styled from "styled-components"
import { HomeFeature } from "../components/Home"
import SEO from "../components/SEO/seo"
import TestimonialIndex from "../components/testimonial"
import {
  CardContainer,
  CommonCard,
  CommonTitle,
  StyledA,
  GroupManageTitle,
  BottomLine,
} from "../components/commonFeatureComp"
import BgImgComp from "../components/bgImgComp"
import { GatsbyImage } from "gatsby-plugin-image"
import bgWeb from "../../images/bg-1.svg"
import bgMobile from "../../images/bg-2.svg"

const H1ImageContainer = styled.div`
  display: grid;
  grid-template-columns: 100%;
  position: relative;
  align-content: center;
`
const H1Container = styled.div`
  display: grid;
  justify-content: center;
  align-items: center;
`

const TitleGrid = styled.div`
  display: grid;
  grid-template-columns: auto;
  justify-content: center;
  margin-top: 10px;
`
const Title = styled.h2`
  font-size: 32px;
  color: #2f394e;
  text-align: center;
  padding: 0 15px;
  @media (max-width: 575px) {
    font-size: 18px;
  }
`
const TitleH1 = styled.h1`
  box-sizing: border-box;
  color: #fff;
  font-size: ${props => (props.fontSize ? props.fontSize : `42px`)};
  margin: 0;
  font-weight: 700;
  line-height: 1.2;
  text-align: center;
  padding: 20px;
  position: absolute;
  width: 100%;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  @media (max-width: 575px) {
    font-size: 23px;
  }
`
const TitleH3 = styled.h3`
  font-size: 24px;
  color: #33475b;
  text-align: start;
  margin: 2rem 0 0.5rem 0;
  @media (max-width: 1280px) {
    font-size: 22px;
  }
`
const Bottomline = styled.div`
  width: 50px;
  height: 3px;
  border-radius: 10px;
  background-color: #33cdd1;
  margin: 0 auto;
`
const TitleTextContainer = styled.div`
  display: grid;
  grid-template-columns: minmax(300px, 1000px);
  justify-content: center;
  padding: 15px 40px;
  @media (max-width: 575px) {
    padding: 15px 20px;
  }
`
const GroupManageTitleContainer = styled.div`
  display: grid;
  grid-template-columns: minmax(1000px, 1250px);
  padding: ${props => props.padding || `3rem 30px`};
  justify-content: center;
  @media (max-width: 1024px) {
    grid-template-columns: 92%;
  }
  @media (max-width: 800px) {
    grid-template-columns: auto;
    padding: 0 20px;
    padding-bottom: ${props => props.isRefund && `25px`};
    text-align: center;
  }
`
const TitleText = styled.p`
  font-size: ${props => props.fontSize || `18px`};
  text-align: ${props => props.textAlign || `center`};
  margin: ${props => props.margin || `1.7rem 0 1rem 0`};
  line-height: 1.6;
  color: #3d4d69;
  @media (max-width: 575px) {
    font-size: 14px;
  }
`
const FeaturesCompareContainer = styled.div`
  max-width: 1250px;
  display: grid;
  grid-template-columns: 1.7fr 1fr 1fr;
  padding: 30px;
  /* justify-items: center; */
  margin: 0 auto;
  background-color: #fff;
  :nth-of-type(even) {
    background-color: #f8fcfc;
  }
  @media (max-width: 768px) {
    grid-template-columns: 1fr 1fr 1fr;
  }
  @media (max-width: 575px) {
    padding: ${props => (props.title ? `25px 0` : `25px 10px`)};
    margin: 0 20px;
    grid-template-columns: 1.5fr 1fr 1fr;
  }
`
const FeatureCompareElement = styled.div`
  margin: 0;
  color: #33475b;
  font-size: ${props => (props.title ? `22px` : `16px`)};
  padding: 0 15px;
  display: grid;
  align-content: center;
  justify-content: ${props => props.icon && `center`};
  font-weight: ${props => props.title && `700`};
  @media (max-width: 575px) {
    padding: ${props => (props.title ? `0` : `0 5px`)};
    font-size: ${props => (props.title ? `14px` : `13px`)};
  }
`

const BrandComponent = styled.div`
  display: grid;
  grid-row-gap: 15px;
  padding: 0 20px;
  @media (max-width: 575px) {
    padding: 0 5px;
  }
`
const FreeTrialCapsule = styled.div`
  color: white;
  font-size: 14px;
  background: ${props => (props.freeTrial ? `#4DBB9D` : `#F95663`)};
  border-radius: 17px;
  padding: 8px 20px;
  margin: 0 auto;
  display: grid;
  place-items: center;
  text-align: center;
  @media (max-width: 575px) {
    display: none;
  }
`

const FreeTrialButton = styled.div`
  display: grid;
  grid-template-columns: auto;
  justify-content: center;
  background-color: #fff;
  padding: 20px 35px;
  border-radius: 6px;
  border: 1px solid #33cbcf;
  font-size: 18px;
  margin: 5vh auto;
  &:hover {
    color: #fff;
    background-color: #33cbcf;
  }
  @media (max-width: 1280px) {
    font-size: 16px;
  }
`
const PlanCardContainer = styled.div`
  display: grid;
  grid-template-columns: 520px 520px;
  grid-column-gap: 40px;
  justify-content: center;
  margin-bottom: 40px;
  @media (max-width: 1200px) {
    grid-template-columns: 1fr 1fr;
    padding: 0 20px;
  }
  @media (max-width: 768px) {
    grid-template-columns: 400px;
    grid-row-gap: 20px;
  }
  @media (max-width: 450px) {
    grid-template-columns: 1fr;
    padding: 0 20px;
  }
`
const PlanCard = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  border: 1px solid #c2cce1;
  border-radius: 30px;
  grid-row-gap: 35px;
  padding: 35px 0;
  background-color: #fff;
`
const PlanCardTableGrid = styled.div`
  display: grid;
  grid-template-columns: auto 1fr;
  grid-gap: 5px 25px;
  padding: 0 35px;
`
const PlanCardText = styled.p`
  margin: 0;
  font-size: ${props => (props.title ? `36px` : `16px`)};
  font-weight: ${props => props.title && `700`};
  padding: ${props => props.title && `0 35px`};
`
const TablePaddedContainer = styled.div`
  background-color: #eafafa;
  padding-top: 30px;
`
const ColoredContainer = styled.div`
  display: grid;
  padding: ${props => (props.isProsCons ? `45px 50px` : `30px 40px`)};
  margin: 30px 0;
  border-radius: 10px;
  background-color: ${props => props.bgColor || `#eafafa`};
  @media (max-width: 575px) {
    padding: ${props => (props.isProsCons ? `25px` : `10px 25px`)};
  }
`
const ImageContainer = styled.div`
  display: grid;
  grid-template-columns: minmax(350px, 1000px);
  justify-content: center;
  padding: 30px 0 1.8rem;
  @media (max-width: 575px) {
    grid-template-columns: 1fr;
  }
`
const WebView = styled.div`
  @media (max-width: 575px) {
    display: none;
  }
`
const MobileView = styled.div`
  display: none;
  @media (max-width: 575px) {
    display: block;
  }
`

const FeatureCompareRow = props => (
  <FeaturesCompareContainer>
    <FeatureCompareElement>{props.featureText}</FeatureCompareElement>
    <FeatureCompareElement icon>
      {props.element.groupboss}
    </FeatureCompareElement>
    <FeatureCompareElement icon>{props.element.groupX}</FeatureCompareElement>
  </FeaturesCompareContainer>
)

const ColorButton = styled.div`
  padding: 16px 30px;
  color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #33cdd1;
  border: 1px solid #33cdd1;
  white-space: nowrap;
  border-radius: 6px;
  font-size: 18px;
  &:focus {
    outline: none;
  }
  &:hover {
    color: #33cdd1;
    background-color: #fff;
  }
`
const ResponsiveImageWeb = styled.img`
  @media (max-width: 575px) {
    display: none;
  }
`
const ResponsiveImageMobile = styled.img`
  display: none;
  @media (max-width: 575px) {
    display: block;
  }
`
const CustomList = styled.div`
  display: grid;
  grid-template-columns: 16px 1fr;
  grid-gap: 25px 15px;
  padding: 25px 0;
  align-items: center;
`
const ColoredDot = styled.div`
  width: 16px;
  height: 16px;
  border-radius: 50%;
  background-color: ${props => props.bgColor || `#92A2FA`};
`
const ListText = styled.p`
  font-size: 18px;
  font-weight: 400;
  text-align: start;
  margin: 0;
  color: #33475b;
`

export default ({ data, location }) => (
  <>
    <SEO
      title="GroupTrack CRM Alternative & Review"
      description="This article showed why people look for GroupTrack alternatives. Learn why Groupboss is the best GroupTrack alternative in 2024."
      pathname={location.pathname}
    />

    <H1ImageContainer>
      <ResponsiveImageWeb src={bgWeb} alt="home-background" width="100%" />
      <ResponsiveImageMobile
        src={bgMobile}
        alt="home-background mobile"
        width="100%"
      />
      <H1Container>
        <TitleH1>The Best GroupTrack CRM Alternative & Review in 2024</TitleH1>
      </H1Container>
    </H1ImageContainer>

    <TitleTextContainer>
      <TitleText>
        If you are looking for a tool that can help you to manage Facebook
        groups and collect leads from there, maybe you have come across
        GroupTrack CRM.
      </TitleText>

      <TitleText>
        It has got quite handsome features for managing Facebook profiles and a
        little for groups. If you look at their pricing, you probably need to
        think multiple times before you make a purchase. There are many tools in
        the market better than GroupTrack CRM for Facebook group automation.
        However, if you want to learn more about GroupTrack CRM, we have got a
        review on this tool at the end of this article.
      </TitleText>
      <TitleText>
        If the question arises, what is the best GroupTrack CRM alternative?.
        You can check out Groupboss which is a premium Facebook group automation
        software with advanced features.
      </TitleText>
    </TitleTextContainer>

    <div
      style={{ display: `grid`, justifyContent: `center`, margin: `20px 0` }}
    >
      <Link
        to="/pricing"
        target="_blank"
        style={{ textDecoration: "none", color: "#33475B", margin: `0 auto` }}
      >
        <ColorButton>Sign Up at Groupboss</ColorButton>
      </Link>
    </div>

    <TitleTextContainer>
      <CommonTitle
        fontSize="32px"
        fontWeight="700"
        margin="4.5rem 0"
        color="#33475B"
        style={{ textAlign: `center` }}
      >
        So, without further delay, let’s have a look at the comparison of
        features between Groupboss and GroupTrack CRM.
      </CommonTitle>
    </TitleTextContainer>

    <TablePaddedContainer>
      <FeaturesCompareContainer title style={{ background: `#eafafa` }}>
        <FeatureCompareElement title>
          Features and Service{" "}
        </FeatureCompareElement>
        <BrandComponent>
          <WebView>
            <GatsbyImage
              image={data.gb_logo.childImageSharp.gatsbyImageData}
              alt="groupboss"
            />
          </WebView>
          <MobileView>
            <GatsbyImage
              image={data.gb_logo_mobile.childImageSharp.gatsbyImageData}
              alt="groupboss logo mobile"
              style={{ width: `50px`, margin: `0 auto` }}
            />
          </MobileView>
        </BrandComponent>
        <BrandComponent>
          <WebView>
            <GatsbyImage
              image={data.grouptrack_crm_logo.childImageSharp.gatsbyImageData}
              alt="GrouptrackCRM logo"
            />
          </WebView>
          <MobileView>
            <GatsbyImage
              image={data.gl_logo_mobile.childImageSharp.gatsbyImageData}
              alt="GrouptrackCRM logo mobile"
              style={{ width: `50px`, margin: `0 auto` }}
            />
          </MobileView>
        </BrandComponent>
      </FeaturesCompareContainer>
      <FeatureCompareRow
        featureText="Contacts Limit"
        element={{ groupboss: "Unlimited members approval", groupX: "Limited(Vaires based on plans)" }}
      />

      <FeatureCompareRow
        featureText="Onboarding and set up"
        element={{
          groupboss: "Very easy",
          groupX: "Complex set up process",
        }}
      />
      <FeatureCompareRow
        featureText="Google sheet Integration"
        element={{
          groupboss: (
            <GatsbyImage
              image={data.tick.childImageSharp.gatsbyImageData}
              alt="tick"
            />
          ),
          groupX: (
            <GatsbyImage
              image={data.tick.childImageSharp.gatsbyImageData}
              alt="tick"
            />
          ),
        }}
      />
      <FeatureCompareRow
        featureText="Dashboard"
        element={{
          groupboss: (
            <GatsbyImage
              image={data.tick.childImageSharp.gatsbyImageData}
              alt="tick"
            />
          ),
          groupX: (
            <GatsbyImage
              image={data.tick.childImageSharp.gatsbyImageData}
              alt="tick"
            />
          ),
        }}
      />

      <FeatureCompareRow
        featureText="Integration with Autoresponders"
        element={{ groupboss: "27 and many more to come", groupX: "5" }}
      />

      <FeatureCompareRow
        featureText="Zapier Required"
        element={{
          groupboss: "Optional",
          groupX: "Required(Other than few integrations)",
        }}
      />
      <FeatureCompareRow
        featureText="Custom & Lookalike Audience "
        element={{
          groupboss: (
            <GatsbyImage
              image={data.tick.childImageSharp.gatsbyImageData}
              alt="tick"
            />
          ),
          groupX: (
            <GatsbyImage
              image={data.cross.childImageSharp.gatsbyImageData}
              alt="cross"
            />
          ),
        }}
      />

      <FeatureCompareRow
        featureText="Export all data from dashboard in CSV format"
        element={{
          groupboss: (
            <GatsbyImage
              image={data.tick.childImageSharp.gatsbyImageData}
              alt="tick"
            />
          ),
          groupX: (
            <GatsbyImage
              image={data.cross.childImageSharp.gatsbyImageData}
              alt="cross"
            />
          ),
        }}
      />

      <FeatureCompareRow
        featureText="Chat Support"
        element={{
          groupboss: (
            <GatsbyImage
              image={data.tick.childImageSharp.gatsbyImageData}
              alt="tick"
            />
          ),
          groupX: (
            <GatsbyImage
              image={data.cross.childImageSharp.gatsbyImageData}
              alt="cross"
            />
          ),
        }}
      />
      <FeatureCompareRow
        featureText="Support in Email"
        element={{
          groupboss: (
            <GatsbyImage
              image={data.tick.childImageSharp.gatsbyImageData}
              alt="tick"
            />
          ),
          groupX: (
            <GatsbyImage
              image={data.tick.childImageSharp.gatsbyImageData}
              alt="tick"
            />
          ),
        }}
      />
      <FeatureCompareRow
        featureText="Starting Price"
        element={{
          groupboss: "$45/Quarter",
          groupX: "$27/Month",
        }}
      />
      <FeatureCompareRow
        featureText="Refund Policy"
        element={{ groupboss: "14 Days", groupX: "7 Days" }}
      />
      <FeatureCompareRow
        featureText="Separate Email column"
        element={{
          groupboss: (
            <GatsbyImage
              image={data.tick.childImageSharp.gatsbyImageData}
              alt="tick"
            />
          ),
          groupX: "Maybe",
        }}
      />

      <FeatureCompareRow
        featureText="Exclusive support through zoom/skype/Gmeet"
        element={{
          groupboss: (
            <GatsbyImage
              image={data.tick.childImageSharp.gatsbyImageData}
              alt="tick"
            />
          ),
          groupX: "Maybe",
        }}
      />
      <FeatureCompareRow
        featureText="Free trial"
        element={{
          groupboss: "Available",
          groupX: "Available",
        }}
      />
      <FeatureCompareRow
        featureText="Free Set Up Call"
        element={{
          groupboss: (
            <GatsbyImage
              image={data.tick.childImageSharp.gatsbyImageData}
              alt="tick"
            />
          ),
          groupX: "Maybe",
        }}
      />
      <FeatureCompareRow
        featureText="Tutorial for all features"
        element={{
          groupboss: (
            <GatsbyImage
              image={data.tick.childImageSharp.gatsbyImageData}
              alt="tick"
            />
          ),
          groupX: "Minimal",
        }}
      />
      <div style={{ display: `grid`, justifyContent: `center` }}>
        <Link
          to="/pricing"
          target="_blank"
          style={{ textDecoration: "none", color: "#33475B" }}
        >
          <FreeTrialButton>Get 7 Days Free Trial at Groupboss</FreeTrialButton>
        </Link>
      </div>
    </TablePaddedContainer>

    <GroupManageTitleContainer>
      <GroupManageTitle>What is GroupTrack CRM?</GroupManageTitle>
      <BottomLine />

      <TitleText textAlign="start">
        Grouptrack CRM is a social media management tool designed for the
        Facebook profiles, pages and some options for groups. If you are looking
        for Facebook profile monitoring, you can check this tool but not for{" "}
        <StyledA
          color="#007AFF"
          textDecoration="underline"
          target="_blank"
          rel="dofollow"
          href="https://groupboss.io/blog/collect-leads-from-facebook-group/"
        >
          generating leads from Facebook groups.
        </StyledA>{" "}
      </TitleText>

      <GroupManageTitle>
        Why do people look for GroupTrack CRM alternatives?
      </GroupManageTitle>
      <BottomLine />

      <TitleText textAlign="start">
        There are multiple reasons why people look for GroupTrack CRM
        alternatives. Let's look at some of the prominent reasons-
      </TitleText>

      <TitleH3>Very Expensive</TitleH3>
      <TitleText textAlign="start">
        Grouptrack CRM is much more expensive compared to what they provide in
        return. It has got an expensive pricing plan that really matters to
        many. That’s why people look for alternatives to Grouptrack before
        making the purchase.
      </TitleText>

      <GroupManageTitle>
        Let’s have a look at the pricing plan:
      </GroupManageTitle>
      <BottomLine />
      <ColoredContainer>
        <TitleText textAlign="start">
          1. Basic plan- <b>$57/Month</b> and <b>$684/Year</b> for only 5000
          contacts
        </TitleText>
        <TitleText textAlign="start">
          2. Premier plan- <b> $97/Month</b> and <b>$1164/Year</b> for only
          10000 contacts
        </TitleText>
        <TitleText textAlign="start">
          3. Pro plan- <b>$157/Month</b> and <b>$1884/Year</b> for only 25000
          contacts.
        </TitleText>
      </ColoredContainer>

      <TitleH3>Complex Setup Process</TitleH3>
      <TitleText textAlign="start">
        There are multiple features in Grouptrack and those are difficult to
        understand. You will need to invest much of your time to get an insight
        on this tool.
      </TitleText>

      <TitleH3>Few Integrations</TitleH3>
      <TitleText textAlign="start">
        Another reason for which people search GroupTrack CRM alternatives is,
        they have got integrations with very few email marketing autoresponders.
        There are only five integrations available.
      </TitleText>

      <TitleH3>
        Too many bloated features, not prioritized for Facebook groups
      </TitleH3>
      <TitleText textAlign="start">
        GroupTrack CRM has many features for managing social media profiles.
        But, if you think for Facebook groups only, they are falling back in
        this aspect. There are very less prioritized features designed for
        Facebook groups. If you are thinking of{" "}
        <StyledA
          color="#007AFF"
          textDecoration="underline"
          target="_blank"
          rel="dofollow"
          href="https://groupboss.io/blog/make-money-using-facebook-group/"
        >
          leveraging your Facebook group,
        </StyledA>{" "}
        which is already done by many entrepreneurs, you need to think of
        GroupTrack CRM alternatives.
      </TitleText>

      <GroupManageTitle>
        Why Should You Choose Groupboss over GroupTrack CRM?
      </GroupManageTitle>
      <BottomLine />
      <TitleText textAlign="start">
        Groupboss is Facebook group automation software consisting of chrome
        extension and dashboard. As an admin/moderator, you will be able to
        collect the answers to the questions that you have already set for your
        new members. Also, you will be able to collect the FB ID link. By integrating with your favorite email marketing autoresponder, you will be able to sync the emails and names of the new joining group members on autopilot. Groupboss has {" "}
        <StyledA
          color="#007AFF"
          textDecoration="underline"
          target="_blank"
          rel="dofollow"
          href="https://groupboss.io/integrations/"
        >
          integration with many email marketing software
        </StyledA>{" "}. 

      </TitleText>
      <TitleText textAlign="start">
        On the other hand, having fewer features of managing Facebook groups,
        GroupTrack CRM is charging much more.
      </TitleText>
    </GroupManageTitleContainer>

    <div style={{ backgroundColor: `#FDFAF3`, paddingBottom: `2rem` }}>
      <GroupManageTitleContainer>
        <TitleH3>1. Pricing</TitleH3>
        <TitleText textAlign="start">
          Groupboss offers cost-effective and reasonable pricing. On the other
          hand, Group Track CRM has higher pricing options. Let’s look at the
          difference in pricing between Groupboss and Group Track CRM.Let’s see
          the difference between the pricing plans to give you a clear
          understanding?
        </TitleText>
      </GroupManageTitleContainer>

      <PlanCardContainer>
        <PlanCard>
          <div style={{ maxWidth: `210px`, paddingLeft: `35px` }}>
            <GatsbyImage
              image={data.gb_logo.childImageSharp.gatsbyImageData}
              alt="groupboss"
            />
          </div>

          <PlanCardText title>
            $45
            <span style={{ fontSize: `22px`, fontWeight: `400` }}>
              {" "}
              / quarter
            </span>
          </PlanCardText>
          <PlanCardTableGrid>
            <PlanCardText>Type of Plan:</PlanCardText>
            <PlanCardText>Groupboss Quarterly</PlanCardText>
            <PlanCardText>No of Groups:</PlanCardText>
            <PlanCardText>3</PlanCardText>
            <PlanCardText>No of Contacts:</PlanCardText>
            <PlanCardText>Unlimited</PlanCardText>
          </PlanCardTableGrid>
          {/* new added end */}
          <div style={{ borderTop: `1px solid #E8ECF3`, paddingTop: `35px` }}>
            <PlanCardText title>
              $99
              <span style={{ fontSize: `22px`, fontWeight: `400` }}>
                {" "}
                / year
              </span>
            </PlanCardText>
          </div>
          <PlanCardTableGrid>
            <PlanCardText>Type of Plan:</PlanCardText>
            <PlanCardText>Groupboss Yearly</PlanCardText>
            <PlanCardText>No of Groups:</PlanCardText>
            <PlanCardText>3</PlanCardText>
            <PlanCardText>No of Contacts:</PlanCardText>
            <PlanCardText>Unlimited</PlanCardText>
          </PlanCardTableGrid>

          <div style={{ borderTop: `1px solid #E8ECF3`, paddingTop: `35px` }}>
            <PlanCardText title>
              $189
              <span style={{ fontSize: `22px`, fontWeight: `400` }}>
                {" "}
                / year
              </span>
            </PlanCardText>
          </div>
          <PlanCardTableGrid>
            <PlanCardText>Type of Plan:</PlanCardText>
            <PlanCardText>Groupboss Yearly (Mega Plan)</PlanCardText>
            <PlanCardText>No of Groups:</PlanCardText>
            <PlanCardText>20</PlanCardText>
            <PlanCardText>No of Contacts:</PlanCardText>
            <PlanCardText>Unlimited</PlanCardText>
          </PlanCardTableGrid>
        </PlanCard>

        <PlanCard>
          <div style={{ maxWidth: `210px`, paddingLeft: `35px` }}>
            <GatsbyImage
              image={data.grouptrack_crm_logo.childImageSharp.gatsbyImageData}
              alt="group track crm logo"
            />
          </div>
          <PlanCardText title>
            $57
            <span style={{ fontSize: `22px`, fontWeight: `400` }}>
              {" "}
              / month,{" "}
            </span>
            $684
            <span style={{ fontSize: `22px`, fontWeight: `400` }}> / year</span>
          </PlanCardText>

          <PlanCardTableGrid>
            <PlanCardText>Type of Plan:</PlanCardText>
            <PlanCardText>Basic</PlanCardText>
            <PlanCardText>No of Contacts:</PlanCardText>
            <PlanCardText>5000</PlanCardText>
          </PlanCardTableGrid>

          <div style={{ borderTop: `1px solid #E8ECF3`, paddingTop: `35px` }}>
            <PlanCardText title>
              $57
              <span style={{ fontSize: `22px`, fontWeight: `400` }}>
                {" "}
                / month,{" "}
              </span>
              $1164
              <span style={{ fontSize: `22px`, fontWeight: `400` }}>
                {" "}
                / year
              </span>
            </PlanCardText>
          </div>
          <PlanCardTableGrid>
            <PlanCardText>Type of Plan:</PlanCardText>
            <PlanCardText>Premier</PlanCardText>
            <PlanCardText>No of Contacts:</PlanCardText>
            <PlanCardText>10000</PlanCardText>
          </PlanCardTableGrid>

          <div style={{ borderTop: `1px solid #E8ECF3`, paddingTop: `35px` }}>
            <PlanCardText title>
              $97
              <span style={{ fontSize: `22px`, fontWeight: `400` }}>
                {" "}
                / month,{" "}
              </span>
              $1184
              <span style={{ fontSize: `22px`, fontWeight: `400` }}>
                {" "}
                / year
              </span>
            </PlanCardText>
          </div>
          <PlanCardTableGrid>
            <PlanCardText>Type of Plan:</PlanCardText>
            <PlanCardText>Pro</PlanCardText>
            
            <PlanCardText>No of Contacts:</PlanCardText>
            <PlanCardText>25000</PlanCardText>
          </PlanCardTableGrid>
        </PlanCard>
      </PlanCardContainer>
      <GroupManageTitleContainer padding="0 30px 3rem 30px">
        <TitleText textAlign="start">
          GroupTrack CRM does not have any lifetime plans like Groupboss.
        </TitleText>
      </GroupManageTitleContainer>
    </div>

    <GroupManageTitleContainer style={{ backgroundColor: `#EAFAFA` }}>
      <TitleH3>2. Clean and Clear UI/UX</TitleH3>
      <TitleText textAlign="start">
        Everyone loves good looks and designs for software. UI is one of the
        important factors that potential customers consider before purchasing a
        tool or software. Groupboss has got a clean and clear UI/UX that is
        loved by its users. Groupboss is super easy to handle and use. You can
        easily explore all its features within a short time.
      </TitleText>
      <ImageContainer>
        <GatsbyImage
          image={data.groupboss_poster.childImageSharp.gatsbyImageData}
          alt="groupboss poster"
        />
      </ImageContainer>
      <TitleText textAlign="start">
        Check the below message to see what Groupboss users are telling about
        its UI.
      </TitleText>
      <ImageContainer>
        <WebView>
          <GatsbyImage
            image={data.clear_ux.childImageSharp.gatsbyImageData}
            alt="feedback clean clear UX"
          />
        </WebView>
        <MobileView>
          <GatsbyImage
            image={data.clear_ux_mobile.childImageSharp.gatsbyImageData}
            alt="feedback clean clear UX"
          />
        </MobileView>
        <TitleText fontSize="16px">
          Source:{" "}
          <span>
            {" "}
            <StyledA
              color="#007AFF"
              textDecoration="underline"
              target="_blank"
              rel="noopener noreferrer"
              href="https://chrome.google.com/webstore/detail/groupboss/gakcpcoikgklfbajjkdaomcfkpeiobfl?hl=en-US"
            >
              Groupboss Chrome Extension
            </StyledA>
          </span>{" "}
        </TitleText>
      </ImageContainer>
    </GroupManageTitleContainer>

    <GroupManageTitleContainer style={{ backgroundColor: `#F4F8FF` }}>
      <TitleH3>3.Higher Satisfaction Rate- Customer Support ROCKS</TitleH3>
      <TitleText textAlign="start">
        Customer satisfaction is a great concern for the Groupboss team and they
        are very positive in helping their users for a better user experience.
        Groupboss is easy to use and you can learn about its features within a
        very short time.
      </TitleText>

      <ImageContainer>
        <WebView>
          <GatsbyImage
            image={data.better_ux.childImageSharp.gatsbyImageData}
            alt="feedback better user experience"
          />
        </WebView>
        <MobileView>
          <GatsbyImage
            image={data.better_ux_mobile.childImageSharp.gatsbyImageData}
            alt="feedback better user experience"
          />
        </MobileView>
        <TitleText fontSize="16px">
          Source:{" "}
          <span>
            {" "}
            <StyledA
              color="#007AFF"
              textDecoration="underline"
              target="_blank"
              rel="noopener noreferrer"
              href="https://chrome.google.com/webstore/detail/groupboss/gakcpcoikgklfbajjkdaomcfkpeiobfl?hl=en-US"
            >
              Groupboss Chrome Extension
            </StyledA>
          </span>{" "}
        </TitleText>
      </ImageContainer>
    </GroupManageTitleContainer>

    <GroupManageTitleContainer style={{ backgroundColor: `#FFF5F7` }}>
      <TitleH3>4. Detailed Tutorials</TitleH3>
      <TitleText textAlign="start">
        Groupboss is equipped with all the tutorials needed for better
        understanding the tool. You will get all the documentation and tutorials
        on their{" "}
        <StyledA
          color="#007AFF"
          textDecoration="underline"
          target="_blank"
          href="https://groupboss.io/help/"
        >
          support page
        </StyledA>{" "}
        and{" "}
        <StyledA
          color="#007AFF"
          textDecoration="underline"
          target="_blank"
          href="https://www.youtube.com/c/Groupboss"
        >
          YouTube
        </StyledA>{" "}
        channel. These resources are helpful for the users and they can access
        those to learn anything about Groupboss.
      </TitleText>
      <ImageContainer>
        <WebView>
          <GatsbyImage
            image={data.detailed_tutorials.childImageSharp.gatsbyImageData}
            alt="feedback detail tutorials"
          />
        </WebView>
        <MobileView>
          <GatsbyImage
            image={
              data.detailed_tutorials_mobile.childImageSharp.gatsbyImageData
            }
            alt="feedback detail tutorials"
          />
        </MobileView>
        <TitleText fontSize="16px">
          Source:{" "}
          <span>
            {" "}
            <StyledA
              color="#007AFF"
              textDecoration="underline"
              target="_blank"
              rel="noopener noreferrer"
              href="https://chrome.google.com/webstore/detail/groupboss/gakcpcoikgklfbajjkdaomcfkpeiobfl?hl=en-US"
            >
              Groupboss Chrome Extension
            </StyledA>
          </span>{" "}
        </TitleText>
      </ImageContainer>
    </GroupManageTitleContainer>

    <GroupManageTitleContainer style={{ backgroundColor: `#EAFAFA` }}>
      <TitleH3>5. Integrations with Email Autoresponders</TitleH3>
      <TitleText textAlign="start">
        So far Groupboss has got integration with 18 different popular email
        marketing autoresponders with all the set-up guides and tutorials. These
        are:
      </TitleText>
      <TitleText textAlign="start">
        <li>MailChimp,</li>
        <li>ActiveCampaign,</li>
        <li>Getresponse, </li>
        <li>ConvertKit, </li>
        <li>Lemlist, </li>
        <li>Snov.io, </li>
        <li>SendFox, </li>
        <li>Moosend, </li>
        <li>MailerLite, </li>
        <li>Automizy, </li>
        <li>Sendgrid, </li>
        <li>Sendinblue, </li>
        <li>Klaviyo,</li>
        <li>Drip,</li>
        <li>Hyros(MarketHero),</li>
        <li>Gist,</li>
        <li>Pabbly,</li>
        <li>GoHighLevel,</li>
        <li>EmailOctopus</li>
        <li>Omnisend</li>
        <li>BenchmarkEmail</li>
        <li>ClickFunnels</li>
        <li>Systeme.io</li>
        <li>Beehiiv</li>
        <li>HubSpot</li>
        <li>Platfmorm.ly</li>

      </TitleText>
      <TitleText textAlign="start">
        However, there are many more autoresponders in the pipeline that will be
        added soon. However, Groupboss team also Add features based customers’
        feedback and suggestion.
      </TitleText>
      <TitleText textAlign="start">
        On the other hand, you will find only four integrations available in
        GroupTrack CRM
      </TitleText>
      <TitleH3>6. Refund Policy</TitleH3>
      <TitleText textAlign="start">
        Groupboss maintains a straight 14 days refund policy and you get the
        scope to use Groupboss for a longer time. There is nothing mentioned
        about the refund policy on the Grouptrack CRM website.
      </TitleText>
    </GroupManageTitleContainer>
    <GroupManageTitleContainer>
      <GroupManageTitle>GroupTrack CRM Review summary</GroupManageTitle>
      <BottomLine />
      <TitleText textAlign="start">
        GroupTrack CRM is a tool to increase your social media organic reach. If
        your marketing strategy is based on Facebook profiles only and you pass
        your time there to find your clients, GroupTrack CRM can help you out in
        this case. But, you will have to invest much of your time in this tool
        to understand its functionality. The overall pricing of this tool is
        also comparatively higher than its competitors.
      </TitleText>
      <TitleText textAlign="start">
        As the number of integrations is very less in Grouptrack CRM, you will
        have to use integrating a tool like Zapier to send the emails from
        Facebook groups to email marketing autoresponders automatically.
      </TitleText>

      <TitleText textAlign="start">
        Let’s check out the Pros and Cons of GroupTrack CRM.
      </TitleText>

      <ColoredContainer isProsCons bgColor="#F8FCFC">
        <TitleH3 style={{ margin: `0 0 1rem` }}>Pros</TitleH3>
        <CustomList>
          <ColoredDot bgColor="#5BEAC3" />
          <ListText>Multiple features for managing Facebook profiles</ListText>
          <ColoredDot />
          <ListText>Team feature, but it needs extra payment</ListText>
          <ColoredDot bgColor="#33CBCF" />
          <ListText>Comment monitoring</ListText>
          <ColoredDot bgColor="#FD99B4" />
          <ListText>Better customer support</ListText>
        </CustomList>

        <TitleH3 style={{ margin: `1.5rem 0 1rem` }}>Cons</TitleH3>
        <CustomList>
          <ColoredDot bgColor="#5BEAC3" />
          <ListText>
            Very Expensive and the pricing is based on the number contacts
          </ListText>
          <ColoredDot />
          <ListText>Fewer email marketing autoresponder integrations</ListText>
          <ColoredDot bgColor="#33CBCF" />
          <ListText>Multiple steps required to configure</ListText>
          <ColoredDot bgColor="#FD99B4" />
          <ListText>Quite hard to understand the tool and get started</ListText>
          <ColoredDot bgColor="#EBCD89" />
          <ListText>Very few tutorials and guidelines. </ListText>
        </CustomList>
      </ColoredContainer>

      <GroupManageTitle>Verdict</GroupManageTitle>
      <BottomLine />
      <TitleText textAlign="start">
        So, you have come to know the differences between Groupboss and
        GroupTrack CRM. Money matters in every field, right?
      </TitleText>

      <TitleText textAlign="start">
        Why should you pay GroupTrack CRM too much once you have got the better
        alternative at a lower price?
      </TitleText>

      <TitleText textAlign="start">
        It’s your turn to decide which one to use.
      </TitleText>
    </GroupManageTitleContainer>
    <TestimonialIndex alternative="groupleads" />
    <CardContainer>
      <CommonCard
        cardTextWeight="500"
        cardText="24/7 Support by real people"
        cardPadding="13px 25px"
      />
      <CommonCard
        cardTextWeight="500"
        cardText="100% Secure payment"
        cardPadding="13px 25px"
      />
      <CommonCard
        cardTextWeight="500"
        cardText="Best customer experience"
        cardPadding="13px 25px"
      />
    </CardContainer>
    <BgImgComp />
  </>
)

export const query = graphql`
  {
    background: file(relativePath: { eq: "background.png" }) {
      childImageSharp {
        gatsbyImageData(quality: 100, layout: FULL_WIDTH)
      }
    }
    artwork_alternative: file(
      relativePath: { eq: "groupboss-groupx-artwork.png" }
    ) {
      childImageSharp {
        gatsbyImageData(quality: 100, placeholder: NONE, layout: FULL_WIDTH)
      }
    }
    gb_logo: file(relativePath: { eq: "gb-logo.png" }) {
      childImageSharp {
        gatsbyImageData(quality: 100, placeholder: NONE, layout: FULL_WIDTH)
      }
    }
    grouptrack_crm_logo: file(relativePath: { eq: "grouptrack-crm-logo.png" }) {
      childImageSharp {
        gatsbyImageData(quality: 100, placeholder: NONE, layout: FULL_WIDTH)
      }
    }
    gb_logo_mobile: file(relativePath: { eq: "grouptrack-crm-logo.png" }) {
      childImageSharp {
        gatsbyImageData(quality: 100, placeholder: NONE, layout: FULL_WIDTH)
      }
    }
    gl_logo_mobile: file(relativePath: { eq: "gl-logo-2.png" }) {
      childImageSharp {
        gatsbyImageData(quality: 100, placeholder: NONE, layout: FULL_WIDTH)
      }
    }
    poor_customer: file(
      relativePath: { eq: "feedback-poor-customer-support.png" }
    ) {
      childImageSharp {
        gatsbyImageData(quality: 100, placeholder: NONE, layout: FULL_WIDTH)
      }
    }
    better_ux: file(
      relativePath: { eq: "feedback-better-user-experience.png" }
    ) {
      childImageSharp {
        gatsbyImageData(quality: 100, placeholder: NONE, layout: FULL_WIDTH)
      }
    }
    clear_ux: file(relativePath: { eq: "feedback-clean-clear-UX.png" }) {
      childImageSharp {
        gatsbyImageData(quality: 100, placeholder: NONE, layout: FULL_WIDTH)
      }
    }
    groupboss_poster: file(relativePath: { eq: "shot-1.png" }) {
      childImageSharp {
        gatsbyImageData(quality: 100, placeholder: NONE, layout: FULL_WIDTH)
      }
    }
    detailed_tutorials: file(
      relativePath: { eq: "feedback-detailed-tutorials.png" }
    ) {
      childImageSharp {
        gatsbyImageData(quality: 100, placeholder: NONE, layout: FULL_WIDTH)
      }
    }

    poor_customer_mobile: file(
      relativePath: { eq: "feedback-poor-customer-support-mobile.png" }
    ) {
      childImageSharp {
        gatsbyImageData(quality: 100, placeholder: NONE, layout: FULL_WIDTH)
      }
    }
    better_ux_mobile: file(
      relativePath: { eq: "feedback-better-user-experience-mobile.png" }
    ) {
      childImageSharp {
        gatsbyImageData(quality: 100, placeholder: NONE, layout: FULL_WIDTH)
      }
    }
    clear_ux_mobile: file(
      relativePath: { eq: "feedback-clean-clear-UX-mobile.png" }
    ) {
      childImageSharp {
        gatsbyImageData(quality: 100, placeholder: NONE, layout: FULL_WIDTH)
      }
    }
    detailed_tutorials_mobile: file(
      relativePath: { eq: "feedback-detailed-tutorials-mobile.png" }
    ) {
      childImageSharp {
        gatsbyImageData(quality: 100, placeholder: NONE, layout: FULL_WIDTH)
      }
    }

    tick: file(relativePath: { eq: "tick_2.png" }) {
      childImageSharp {
        gatsbyImageData(
          quality: 100
          width: 27
          placeholder: NONE
          layout: FIXED
        )
      }
    }
    cross: file(relativePath: { eq: "cross.png" }) {
      childImageSharp {
        gatsbyImageData(
          quality: 100
          width: 27
          placeholder: NONE
          layout: FIXED
        )
      }
    }
  }
`
